import * as React from "react";
import Layout from "../components/Layout";

const About = () => {
  return (
    <Layout pageTitle="About Me">
      <h2>👋 Hi there! Welcome to Robot Boulevard, my Web home.</h2>
      <p>
      Here is where I share my thoughts and experiences on computers, coding, history and many other things along the way to build good software and be a better thinker.
      </p>
      <p>
      I love building software and having a better understanding of our society. I spend tons of my time improving myself as a proficient programmer, a solid architect and a deep thinker. 
I try to contribute to a world where we value skills and relationships over money and career, and where we are responsible for our knowledge instead of only trusting what we are told and taught.      </p>
      I've set my self on a mission: making a positive impact in people's lives through well designed software. 
    </Layout>
  );
};

export default About;
